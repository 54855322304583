


























































import Vue from 'vue'
import { GridLayout, GridItem, GridItemData } from 'vue-grid-layout'
import { cloneDeep as _cloneDeep, findIndex as _findIndex } from 'lodash'
import { mapState } from 'vuex'
import DerivativeInfos from './components/DerivativeInfos/index.vue'
import TerminalFormFilter from './components/TerminalFormFilter/index.vue'
import TradeOrderForm from './components/TradeOrderForm/index.vue'
import TradeRecords from './components/TradeRecords/index.vue'
import RecentTrade from './components/RecentTrade/index.vue'
import ExchangeComparison from './components/ExchangeComparison/index.vue'
import TradingView from './components/TradingView/index.vue'
import PositionOrders from './components/PositionOrders/index.vue'
import { DEFAULT_LAYOUT } from './constant'
import ConfirmOrderDialog from './components/TradeOrderForm/components/ConfirmOrderDialog.vue'
import { OrderReq } from '@/services/order/models'
import EditOrderDialog from '@/components/EditOrderDialog.vue'
import { POSITION_EXCHANGE } from '@/constants/exchange'

interface CustomData extends GridItemData {
  component: string
  minW: number
  minH: number
  name: string
}

enum BREAKPOINT {
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
  XS = 'xs',
  XXS = 'xxs'
}

type HeightControl = {
  maxHeightTableContent: number
  maxHeightOrderForm: number
  maxHeightExchangeRecords: number
}

export default Vue.extend({
  components: {
    TradeOrderForm,
    DerivativeInfos,
    TradeRecords,
    TerminalFormFilter,
    RecentTrade,
    PositionOrders,
    GridLayout,
    GridItem,
    ConfirmOrderDialog,
    ExchangeComparison,
    EditOrderDialog,
    TradingView
  },

  data(): {
    defaultLayout: CustomData[]
    responsiveLayouts: unknown
    visibleComponent?: Record<string, boolean>
    breakpoint?: BREAKPOINT
    heightControl: HeightControl
    isVisibleConfirmOrderDialog: boolean
    paramOrderRequest: OrderReq | null
    orderPriceUpdated: number
    isOrderPriceEditByItSelf: boolean
    layout: CustomData[]
  } {
    return {
      paramOrderRequest: null,
      isVisibleConfirmOrderDialog: false,
      defaultLayout: [],
      responsiveLayouts: _cloneDeep(DEFAULT_LAYOUT),
      visibleComponent: undefined,
      breakpoint: BREAKPOINT.LG,
      heightControl: {
        maxHeightTableContent: 416, // default max height to have scroll content inside table,
        maxHeightOrderForm: 440, // default max height to have scroll content inside order form
        maxHeightExchangeRecords: 566 // default max height to have scroll content inside exchange records
      },
      orderPriceUpdated: 0,
      isOrderPriceEditByItSelf: false,
      layout: []
    }
  },

  computed: {
    ...mapState('editOrderModal', ['open']),

    ...mapState('exchange', ['exchange'])
  },

  methods: {
    resetLayout() {
      switch (this.breakpoint) {
        case BREAKPOINT.LG:
          this.defaultLayout = _cloneDeep(DEFAULT_LAYOUT.lg)
          break

        case BREAKPOINT.MD:
        case BREAKPOINT.SM:
          this.defaultLayout = _cloneDeep(DEFAULT_LAYOUT.md)
          break

        // default:
        //   this.defaultLayout = _cloneDeep(DEFAULT_LAYOUT.xs)
        //   break
      }
      this.visibleComponent = {
        terminalVisible: true,
        orderBookVisible: true,
        recentTradeVisible: true,
        tableVisible: true,
        exchangeInfoVisible: true,
        tradingViewVisible: true,
        positionVisible: POSITION_EXCHANGE.includes(this.exchange)
      }

      this.layout = this.defaultLayout
    },

    toggleComponent(val: Record<string, boolean>) {
      const _DEFAULT_LAYOUT_LG = _cloneDeep(DEFAULT_LAYOUT.lg)
      switch (this.breakpoint) {
        case BREAKPOINT.LG:
          if (!val.orderBookVisible) {
            const indexRecentTradeVisible = _findIndex(_DEFAULT_LAYOUT_LG, { name: 'recentTradeVisible' })
            _DEFAULT_LAYOUT_LG[indexRecentTradeVisible].h = 12
          }

          if (!val.recentTradeVisible) {
            const indexOrderBookVisible = _findIndex(_DEFAULT_LAYOUT_LG, { name: 'orderBookVisible' })
            _DEFAULT_LAYOUT_LG[indexOrderBookVisible].h = 12
          }

          this.defaultLayout = _DEFAULT_LAYOUT_LG.filter((item: CustomData) => val[item.name])
          break

        case BREAKPOINT.MD:
        case BREAKPOINT.SM:
          this.defaultLayout = _cloneDeep(DEFAULT_LAYOUT.md).filter((item: CustomData) => val[item.name])
          break

        // default:
        //   this.defaultLayout = _cloneDeep(DEFAULT_LAYOUT.xs).filter((item: CustomData) => val[item.name])
        //   break
      }

      this.layout = this.defaultLayout
    },

    breakpointChangedEvent(newBreakpoint: BREAKPOINT, newLayout: CustomData[]) {
      this.defaultLayout = newLayout
      this.breakpoint = newBreakpoint
      this.layout = this.defaultLayout
    },

    resizedEvent(i: string, newH: number, newW: number, newHPx: number) {
      switch (parseInt(i)) {
        case 3: // tradeRecords component
          this.heightControl.maxHeightTableContent = newHPx - 120
          break
        case 0: // orderForm component
          this.heightControl.maxHeightOrderForm = newHPx - 118
          break
        case 6: // ExchangeComparison component
          this.heightControl.maxHeightExchangeRecords = newHPx
          break
      }
    },

    onOrderExchange(val: OrderReq) {
      this.paramOrderRequest = val
      this.isVisibleConfirmOrderDialog = true
    },

    onUpdateOrderPrice(orderPrice: number, isOrderPriceEditByItSelf: boolean) {
      this.orderPriceUpdated = Number(orderPrice)
      this.isOrderPriceEditByItSelf = isOrderPriceEditByItSelf
    }
  },

  created() {
    // FIXME: update
    // this.visibleComponent = localStorage.getItem(StorageKeys.visibleComponent)
    //   ? JSON.parse(localStorage.getItem(StorageKeys.visibleComponent) as string)
    //   : {
    //       terminalVisible: true,
    //       orderBookVisible: true,
    //       recentTradeVisible: true,
    //       tableVisible: true
    //     }

    this.visibleComponent = {
      terminalVisible: true,
      orderBookVisible: true,
      recentTradeVisible: true,
      tableVisible: true,
      exchangeInfoVisible: true,
      tradingViewVisible: true,
      positionVisible: POSITION_EXCHANGE.includes(this.exchange)
    }
  },

  mounted() {
    this.resetLayout()
  },

  watch: {
    breakpoint() {
      this.resetLayout()
    },

    exchange() {
      this.visibleComponent = {
        ...this.visibleComponent,
        positionVisible: POSITION_EXCHANGE.includes(this.exchange)
      }
    }
  }
})
