export const POSITION_EXCHANGE = [
  'bitmex',
  'deribit',
  'ftx',
  'ftxFutures',
  'binanceFutures',
  'krakenFutures',
  'kraken',
  'bitfinex',
  'binanceMarginCross',
  'kuCoinFutures',
  'gateioDelivery',
  'gateioFutures'
].reduce((acc: string[], currentValue: string) => {
  acc.push(currentValue)
  acc.push(`${currentValue}Testnet`)

  return acc
}, [])
