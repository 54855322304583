var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppSection',{class:_vm.$style.section},[_c('div',{class:_vm.$style.table},[_c('ElTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":480,"stripe":""}},[_c('ElTableColumn',{attrs:{"label":"Symbol","min-width":"120","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:_vm.$style.tile},[_c('p',{class:_vm.$style.title},[_vm._v(_vm._s(row.symbol))])])]}}])}),_c('ElTableColumn',{attrs:{"prop":"side","label":"Side","min-width":"100","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ElTag',{attrs:{"type":row.side.toUpperCase() === 'B' ? 'primary' : 'danger',"effect":"light","size":"mini"}},[_vm._v(" "+_vm._s(row.side.toUpperCase() === 'B' ? 'BUY' : 'SELL')+" ")])]}}])}),_c('ElTableColumn',{attrs:{"label":"Order Size","min-width":"100","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.remaining_qty ? row.remaining_qty : 0)+" ")]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Order "),_c('br'),_vm._v(" Size ")])])],2),_c('ElTableColumn',{attrs:{"label":"Remaining Size","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(" "+_vm._s(row.qty_base_ccy_remaining ? ['eur, usdt', 'usd'].includes(row.base_ccy && row.base_ccy.toLowerCase()) ? _vm.$filters.toFloatNumberWithCommas(row.qty_base_ccy_remaining) : _vm.$filters.toFloatNumberWithCommas(row.qty_base_ccy_remaining) : 0)+" "+_vm._s(row.base_ccy)+" ")]),_c('p',[_vm._v(" "+_vm._s(row.qty_quote_ccy_remaining ? ['eur, usdt', 'usd'].includes(row.quote_ccy && row.quote_ccy.toLowerCase()) ? _vm.$filters.toFloatNumberWithCommas(row.qty_quote_ccy_remaining) : _vm.$filters.toFloatNumberWithCommas(row.qty_quote_ccy_remaining, 8) : 0)+" "+_vm._s(row.quote_ccy)+" ")])]}}])},[_c('template',{slot:"header"},[_c('p',[_vm._v(" Remaining "),_c('br'),_vm._v(" Size ")])])],2),_c('ElTableColumn',{attrs:{"label":"Entry Price, $","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.entry_price ? row.entry_price : 0)+" ")]}}])},[_c('template',{slot:"header"},[_c('p',[_vm._v(" Entry "),_c('br'),_vm._v(" Price, $ ")])])],2),_c('ElTableColumn',{attrs:{"label":"Liquidation Price, $","min-width":"100","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.liquidation_price ? _vm.$filters.toFloatNumberWithCommas(row.liquidation_price, 2) : 0)+" ")]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Liquidation "),_c('br'),_vm._v(" Price, $ ")])])],2),_c('ElTableColumn',{attrs:{"label":"Margin","min-width":"100","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.margin ? _vm.$filters.toFloatNumberWithCommas(row.margin, 8) : 0)+" ")]}}])}),_c('ElTableColumn',{attrs:{"label":"PnL","min-width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{class:[_vm.$style.rate, row.realised_pnl > 0 && _vm.$style.up, row.realised_pnl < 0 && _vm.$style.down]},[_vm._v(" Realised PnL: "+_vm._s(row.realised_pnl ? ['eur, usdt', 'usd'].includes(row.pnl_ccy.toLowerCase()) ? _vm.$filters.toFloatNumberWithCommas(row.realised_pnl) : _vm.$filters.toFloatNumberWithCommas(row.realised_pnl, 8) : 0)+" "+_vm._s(row.pnl_ccy)+" "),(row.realised_pnl > 0)?_c('i',{staticClass:"el-icon-sort-up"}):_vm._e(),(row.realised_pnl < 0)?_c('i',{staticClass:"el-icon-sort-down"}):_vm._e()]),_c('p',{class:[_vm.$style.rate, row.unrealised_pnl > 0 && _vm.$style.up, row.unrealised_pnl < 0 && _vm.$style.down]},[_vm._v(" Unrealised PnL: "+_vm._s(row.unrealised_pnl ? _vm.$filters.toFloatNumberWithCommas(row.unrealised_pnl, 8) : 0)+" "+_vm._s(row.pnl_ccy)+" "),(row.unrealised_pnl > 0)?_c('i',{staticClass:"el-icon-sort-up"}):_vm._e(),(row.unrealised_pnl < 0)?_c('i',{staticClass:"el-icon-sort-down"}):_vm._e()]),_c('p',{class:_vm.$style.rate},[_vm._v("PnL: "+_vm._s(row.pnl_ccy ? row.pnl_ccy : '-'))])]}}])}),_c('ElTableColumn',{attrs:{"prop":"status","label":"Status","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ElTag',{attrs:{"type":row.status.toLowerCase() === 'open' ? 'primary' : 'danger',"effect":"light","size":"mini"}},[_vm._v(" "+_vm._s(row.status)+" ")])]}}])}),_c('ElTableColumn',{attrs:{"prop":"timestamp","label":"Date","min-width":"100","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$filters.moment_llll(row.timestamp))+" ")]}}])})],1),_c('div',{class:_vm.$style.refresh},[_c('ElButton',{attrs:{"loading":_vm.loading,"type":"primary","icon":"el-icon-refresh","size":"small"},on:{"click":_vm.getPosition}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }