

















































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import PositionService from '@/services/position'
import { ExchangePosition } from '@/services/position/model'
import { SIDE_ORDER } from '@/services/order/models'

export default Vue.extend({
  data(): {
    tableData: ExchangePosition[]
    loading: boolean
  } {
    return {
      tableData: [],
      loading: false
    }
  },

  computed: {
    ...mapState('exchange', ['exchange']),

    SIDES() {
      return SIDE_ORDER
    }
  },

  watch: {
    exchange: {
      handler() {
        this.getPosition()
      },
      immediate: true
    }
  },

  methods: {
    async getPosition() {
      if (!this.exchange) return
      this.loading = true

      try {
        const res = await PositionService.getPosition({ exchange: this.exchange })
        this.tableData = res.data.custom?.data || []
      } finally {
        this.loading = false
        this.$emit('update:refresh', false)
      }
    }
  }
})
